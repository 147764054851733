
import Vue from "vue";
import eventBus from "@/application/eventBus";

type Callback = () => void;

interface ComponentData {
  showDialog: boolean;
  confirmCallback: null | Callback;
  title: string;
  body: string;
  showCancel: boolean;
  cancelText?: string;
  confirmText?: string;
}

export default Vue.extend({
  name: "TheConfirmationDialog",
  data: (): ComponentData => ({
    showDialog: false,
    confirmCallback: null,
    title: "",
    body: "",
    cancelText: undefined,
    confirmText: undefined,
    showCancel: true,
  }),
  created() {
    eventBus.$on("confirm", (params: ComponentData) => {
      this.title = params.title;
      this.body = params.body;
      this.showCancel = params.showCancel ?? true;
      this.confirmCallback = params.confirmCallback;
      this.cancelText = params.cancelText;
      this.confirmText = params.confirmText;
      this.showDialog = true;
    });
  },
  methods: {
    handleContinue(): void {
      this.showDialog = false;

      if (typeof this.confirmCallback === "function") {
        this.confirmCallback();
      }
    },
  },
});
