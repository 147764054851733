
import eventBus from "@/application/eventBus";
import Vue from "vue";

interface SnackbarMessage {
  text: string;
  type?: string;
  color?: string;
  milliseconds?: number;
  id: number;
}

interface ComponentData {
  messages: SnackbarMessage[];
}

export default Vue.extend({
  name: "TheSnackbarQueueHandler",
  data: (): ComponentData => ({
    messages: [],
  }),
  created(): void {
    eventBus.$on("snackbar", (snackbar: SnackbarMessage | string) => {
      let ms = new Date().valueOf();
      const message = {
        text:
          typeof snackbar === "string"
            ? snackbar
            : (snackbar as SnackbarMessage).text,
        type:
          typeof snackbar === "string"
            ? "success"
            : (snackbar as SnackbarMessage).type ||
              (snackbar as SnackbarMessage).color,
        id: ms + Math.floor(Math.random() * 1000),
      };
      this.messages.push(message);

      const snackbarDuration =
        (typeof snackbar === "object" && snackbar?.milliseconds) || 10000;

      setTimeout(() => {
        const found = this.messages.find((find) => find.id === message.id);
        if (!found) return;
        this.messages.splice(this.messages.indexOf(found), 1);
      }, snackbarDuration);
    });
  },
});
