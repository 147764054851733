import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import en from "vuetify/src/locale/en";
import SvgRenderer from "@/components/SvgRenderer.vue";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { en },
    current: "en",
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: "#585857",
        accent: "#4b9fa9",
        error: "#E65100",
        teal: "#34A2AB",
      },
    },
  },
  icons: {
    iconfont: "fa",
    values: {
      complete: "far fa-check",
      delete: "far fa-trash-alt",
      success: "far fa-check-circle",
      info: "far fa-info-circle",
      warning: "far fa-exclamation",
      error: "far fa-exclamation-triangle",
      checkboxOn: "fas fa-check-square",
      checkboxOff: "far fa-square",
      checkboxIndeterminate: "fas fa-minus-square",
      delimiter: "far fa-circle",
      menu: "far fa-bars",
      radioOn: "far fa-dot-circle",
      radioOff: "far fa-circle",
      edit: "far fa-pencil-alt",
      ratingEmpty: "far fa-star",
      ratingFull: "far fa-star",
      ratingHalf: "far fa-star-half",
      loading: "far fa-sync",
      first: "far fa-step-backward",
      last: "far fa-step-forward",
      unfold: "far fa-arrows-alt-v",
      file: "far fa-paperclip",
      plus: "far fa-plus",
      flag: "far fa-flag",
      arrowLeft: "far fa-arrow-left",
      arrowRight: "far fa-arrow-right",
      houreglass: "far fa-hourglass",
      minus: "far fa-minus",
      xmark: "far fa-xmark",

      // App specific icons
      infoFill: "fas fa-info-circle",
      filter: "far fa-filter",
      search: "far fa-search",
      salesOrder: "far fa-shopping-cart",
      upload: "far fa-upload",
      outOfStock: "far fa-shelves-empty",
      stock: "far fa-shelves",
      delivery: "far fa-truck-ramp-box",
      invoice: "far fa-file-invoice-dollar",
      products: "far fa-cart-shopping-fast",
      orderInfo: "far fa-calendar-pen",
      notes: "far fa-comment-pen",
      addressBook: "far fa-address-book",
      followLink: "far fa-up-right-from-square",
      tracking: "far fa-truck",
      email: "far fa-envelope",
      phone: "far fa-phone",
      history: "far fa-clock-rotate-left",
      view: "far fa-search",
      prev: { component: SvgRenderer, props: { icon: "chevron-left" } },
      next: { component: SvgRenderer, props: { icon: "chevron-right" } },
      dropdown: { component: SvgRenderer, props: { icon: "chevron" } },
      sort: { component: SvgRenderer, props: { icon: "chevron" } },
      expand: { component: SvgRenderer, props: { icon: "chevron" } },
      subgroup: { component: SvgRenderer, props: { icon: "chevron" } },
      clear: { component: SvgRenderer, props: { icon: "close" } },
      close: { component: SvgRenderer, props: { icon: "close" } },
      cancel: { component: SvgRenderer, props: { icon: "close" } },
      box: { component: SvgRenderer, props: { icon: "box" } },
      cart: { component: SvgRenderer, props: { icon: "cart" } },
      dots: { component: SvgRenderer, props: { icon: "dots" } },
      ship: { component: SvgRenderer, props: { icon: "ship" } },
      gear: { component: SvgRenderer, props: { icon: "gear" } },
      headset: { component: SvgRenderer, props: { icon: "headset" } },
      users: { component: SvgRenderer, props: { icon: "users" } },
      chart: { component: SvgRenderer, props: { icon: "chart" } },
      retour: { component: SvgRenderer, props: { icon: "retour" } },
    },
  },
});
